import type { FC } from 'react';
import React, { useEffect, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FileUpload from '../components/fileupload';
import CreateFolder from '../components/createfolder';
import FileBrowser from '../components/filebrowser';
import SearchFiles from '../components/searchfiles';
import Icon from '../components/icons';
import FileSats from '../components/filestats';
//files interface
interface filesProps {}
const Files: FC<filesProps> = ({}) => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [folderPath, setFolderPath] = useState<string>('/');
  const [counter, setCounter] = useState(0);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [groups, setGroups] = useState<Array<any>>([]);
  const [showUploadFiles, setShowUploadFiles] = useState(false);

  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
            setLoading(false);
            setPageLoaded(true);
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  const updateFolder = () => {
    console.log('update folder');
    setCounter((counter) => counter + 1);
  };
  const updateFolderPath = (path: string) => {
    setFolderPath(path);
  };
  const getgroups = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-all-groups.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setGroups(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [activeTab, setActiveTab] = useState(0);
  const toggleActiveTab = () => {
    setActiveTab((prevTab) => (prevTab === 0 ? 1 : 0));
  };
  const toggleCreateFileModal = () => {
    setShowUploadFiles(!showUploadFiles);
  };
  useEffect(() => {
    if (user.groups?.includes(0) || user.groups?.includes(1)) {
      getgroups();
    }
    checkStatus();
  }, []);
  useEffect(() => {
    if (showUploadFiles) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showUploadFiles]);

  const roles = JSON.parse(user.groups || '[]');
  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };

  return (
    pageLoaded && (
      <>
        <div className="relative z-10 flex justify-between px-5 py-3 border-b border-slate-100 md:pl-5 lg:pl-10 xl:pl-20 md:py-7 md:pr-7">
          <div className="grid content-center">
            <SearchFiles
              path={folderPath}
              updateFolderPath={updateFolderPath}
            />
          </div>
          <div className="flex items-center">
            <p
              className="hidden text-xs font-medium leading-6 md:block md:text-sm "
              aria-hidden="true"
            >
              {user?.firstname} {user?.lastname}
            </p>
            <p className="ml-2 text-slate-300">|</p>

            <a
              onClick={logout}
              className={`cursor-pointer hover:text-slate-500
                    group  gap-x-2 p-2 text-sm leading-6 flex justify-center lg:justify-start items-center`}
            >
              <Icon iconName={`logout`} />
              <span className="hidden lg:block">Logout</span>
            </a>
          </div>
        </div>
        {activeTab === 0 ? (
          <>
            <div className="flex gap-3 p-3 mb-3 lg:pt-10 md:pl-5 lg:pl-10 xl:pl-16 fade-in">
              <button
                onClick={toggleCreateFileModal}
                className="px-4 py-2 text-sm border rounded-md md:text-base border-suzy-grey"
              >
                Upload files
              </button>
              <button className="px-4 py-2 text-sm border rounded-md md:text-base border-suzy-grey">
                Create folder
              </button>
            </div>
            <FileBrowser
              toggleActiveTab={toggleActiveTab}
              folderPath={folderPath}
              changeFolderPath={updateFolderPath}
              refreshCounter={counter}
              groups={groups}
            />

            {(roles.includes(0) || roles.includes(1)) && showUploadFiles && (
              <div className="fixed top-0 left-0 z-10 grid content-center w-full h-full bg-opacity-50 p-7 bg-default">
                <div className="w-full md:w-[500px] max-w-full mx-auto">
                  <FileUpload
                    updateFolder={updateFolder}
                    folderPath={folderPath}
                    groups={groups}
                    toggleCreateFileModal={toggleCreateFileModal}
                  />
                </div>
              </div>
            )}
            {(roles.includes(0) || roles.includes(1)) && (
              <CreateFolder
                updateFolderPath={updateFolderPath}
                folderPath={folderPath}
                groups={groups}
              />
            )}
          </>
        ) : (
          <div className="relative z-10 p-3 lg:pb-20 md:pt-10 md:p-5 lg:pl-10 xl:pl-16 fade-in">
            <FileSats toggleActiveTab={toggleActiveTab} />
          </div>
        )}
      </>
    )
  );
};
export default Files;
