import type { FC } from 'react';
import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface createFolderProps {
  folderPath: string;
  updateFolderPath: (path: string) => void;

  groups: Array<any>;
}

const CreateFolder: FC<createFolderProps> = ({
  folderPath,
  updateFolderPath,
  groups,
}) => {
  const [loading, setLoading] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedGroups, setselectedGroups] = useState<any>([]);
  const [folderError, setFolderError] = useState('');
  const [folderSuccess, setFolderSuccess] = useState('');

  const context = useContext(AppContext);
  const navigate = useNavigate();
  const sendUpFolderPath = (path: string) => {
    updateFolderPath(path);
  };

  const createFolder = async () => {
    setLoading(true);
    setFolderError('');
    setFolderSuccess('');
    const storedJWT = localStorage.getItem('jwt');
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('image', file); // Use "files[]" to handle multiple files
    });
    formData.append('access_level', selectedGroups.join(','));
    formData.append('folder_path', folderPath);
    formData.append('folder_name', folderName);
    //check if image is jpg or png
    if (selectedFiles.length > 0) {
      const image = selectedFiles[0];
      const imageType = image.type;
      if (imageType !== 'image/jpeg' && imageType !== 'image/png') {
        setFolderError('Image must be jpg or png');
        setLoading(false);
        return;
      }
    }
    if (folderName.trim() === '') {
      setFolderError('Folder name is required');
      setLoading(false);
    } else {
      await axios
        .post(process.env.REACT_APP_API_URL + '/create-folder.php', formData, {
          headers: {
            Authorization: 'Bearer ' + storedJWT,
          },
        })
        .then((response: any) => {
          const jsonData = response.data;

          if (jsonData.message) {
            setFolderSuccess(jsonData.message);
            if (jsonData.message === 'Folder created successfully.') {
              setFolderName('');
              setFolderError('');
              setLoading(false);
              setselectedGroups([]);
              setSelectedFiles([]);
              sendUpFolderPath(jsonData.folder_path);
              //clear file input
              const fileInput = document.getElementById(
                'image'
              ) as HTMLInputElement;
              if (fileInput) {
                fileInput.value = '';
              }
            } else {
              setFolderError(jsonData.response.error);
            }
          } else if (jsonData.error) {
            setFolderError(jsonData.error);
          } else if (jsonData === 'Error decoding token: Expired token') {
            logout();
          }
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error?.response?.data?.error);
          setFolderError(error?.response?.data?.error);
          setLoading(false);
        });
    }
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setSelectedFiles(filesArray);
    }
  };
  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };

  return (
    <div className="inline-block pr-6 mt-5 bg-white fade-in md:rounded-lg md:shadow-md md:p-6">
      <h1 className="mb-4 text-xl text-gray-800 uppercase">Create folder</h1>
      <input
        className="block w-full px-2 py-1 text-gray-700 border border-gray-300 rounded-md"
        type="text"
        name="folder-name"
        placeholder="Folder name"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
      />

      <div className="mt-4">
        <label
          htmlFor="access-level"
          className="block mb-2 text-sm font-medium text-gray-700"
        >
          Groups:
        </label>
        <div className="mt-1 mb-3">
          <select
            id="groups"
            name="groups"
            required
            multiple
            value={selectedGroups}
            onChange={(event) => {
              // convert selected options to an array and update state
              const selectedOptions = Array.from(
                event.target.selectedOptions,
                (option) => option.value
              );
              setselectedGroups(selectedOptions);
            }}
            className={`text-default block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm`}
          >
            <option disabled value="">
              Select groups
            </option>

            {Array.isArray(groups) &&
              groups.map((group) => (
                <option key={group.ID} value={group.ID}>
                  {group.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="mt-4 mb-6">
        <label
          htmlFor="image"
          className="block mb-3 text-sm font-medium text-gray-700"
        >
          Image:
        </label>
        <input
          className="block w-full text-sm text-gray-700 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-slate-200 file:text-slate-700 hover:file:bg-slate-100"
          type="file"
          name="image"
          id="image"
          onChange={handleFileChange}
        />
      </div>
      {loading ? (
        <svg
          className="inline-block w-8 h-8 text-accent animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        <button
          className="inline-flex justify-center w-auto px-4 py-2 mt-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-default hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
          onClick={createFolder}
        >
          Create
        </button>
      )}

      <p className="mt-2 text-sm font-medium text-green-600">{folderSuccess}</p>
      <p className="mt-2 text-sm font-medium text-red-600">{folderError}</p>
    </div>
  );
};

export default CreateFolder;
